body, html, .app {
  width:100vw;
  height:100vh;
  margin:0;
  padding:0;
  background:linear-gradient(230deg, #a24bcf, #4b79cf, #4bc5cf);
  -webkit-animation: low 30s ease infinite;
  -moz-animation:flow 30s ease infinite;
  animation:flow 30s ease infinite;
}

.main {
  display: block;
}

.main .phrase {
  display: inline-block;
  width:100vw;
  position: absolute;
  top:32vh;
  left:0;
  font-size:1.85rem;
  font-family: impact;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
  color:#fff;
  text-shadow: 2px 2px #00000040;
}

.main .button {
  width:150px;
  height:42px;
  position:absolute;
  top:55vh;
  left:calc(50% - 75px);
  background:#fff;
  border-radius: 3px;
  font-size:1.4rem;
  font-family: impact;
  font-weight:200;
  letter-spacing:1px;
  line-height:42px;
  text-align:center;
  background:#4d4d4d;
  color:#fff;
  user-select:none;
  cursor:pointer;
  box-shadow:1.5px 1.5px 1.5px rgba(0,0,0,0.5);
  transition:0.15s;
}

.main .button:active {
  background:#474747 !important;
  box-shadow:1px 1px 1px rgba(0,0,0,0.5) !important;
  color:rgba(255,255,255,0.9)
}

.main .button:hover {
  /* background:#5c5c5c */
}

@-webkit-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@-moz-keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
@keyframes AnimationName {
  0%{background-position:0% 50%}
  50%{background-position:100% 50%}
  100%{background-position:0% 50%}
}
